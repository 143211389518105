<template>
    <Doughnut
        :chart-options="chartOptions"
        :chart-data="chart"
        :width="width"
        :height="height"
    />
</template>

<script>
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'vue-chartjs';

// 円グラフ上にラベルを表示する場合はプラグインが必要
// https://tech-market.org/vuechartjs-pie-label-on-graph/
ChartJS.register(ArcElement, Tooltip, Legend)

export default {
    name: 'DoughnutGraph',
    components: { Doughnut },
    props: {
        width: {
            type: Number,
            default: 400
        },
        height: {
            type: Number,
            default: 400
        },
        chart: {
            type: Object,
            default: () => {}
        },
    },
    data() {
        return {
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
            }
        }
    },
}
</script>