<template>
    <div class="d-flex flex-wrap align-items-end">
        <template v-for="(option, i) in options" :key="i">
            <div class="form-check mb-2 me-3">
                <label class="form-check-label">
                    <input
                        type="checkbox"
                        class="form-check-input"
                        :value="option[option_value]"
                        v-model="checkedOptions"
                        @change="$emit('update:modelValue', this.checkedOptions)"
                        true-value="option[option_value]"
                    >
                    {{ option[option_label] }}
                </label>
            </div>
        </template>
        <template v-if="show_toggle">
            <button type="button" class="btn btn-link" v-if="is_checked_all" @click="uncheck_all">全解除</button>
            <button type="button" class="btn btn-link" v-else @click="check_all">全選択</button>
        </template>
    </div>
</template>

<script>
export default {
    name: 'FormCheckboxes',
    props: {
        modelValue: {
            type: Array
        },
        options: {
            type: Array
        },
        option_value: {
            type: String,
            default: 'value'
        },
        option_label: {
            type: String,
            default: 'label'
        },
        show_toggle: {
            type: Boolean,
            default: false
        },
    },
    emits: [
        'update:modelValue'
    ],
    data() {
        return {
            checkedOptions: this.modelValue,
        }
    },
    watch: {
        modelValue() {
            this.checkedOptions = this.modelValue;
        },
    },
    methods: {
        check_all() {
            this.$emit('update:modelValue', this.options.map(option => option[this.option_value]));
        },
        uncheck_all() {
            this.$emit('update:modelValue', []);
        },
    },
    computed: {
        is_checked_all() {
            return this.modelValue?.length === this.options?.length;
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
