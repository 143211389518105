<template>
    <header class="page-title">
        <h1><i class="bi bi-gear-fill"></i> レポート - 反響実績</h1>
    </header>

    <!-- 検索欄 -->
    <section class="section">
        <div class="row align-items-end">
            <div class="col-36">
                <label class="form-label">事業部</label>
                <form-checkboxes
                    v-model="condition.departments"
                    :options="options_department"
                    :show_toggle="true"
                >
                </form-checkboxes>
            </div>
        </div>
        <div class="row align-items-end">
            <div class="col-12">
                <label class="form-label">集計期間</label>
                <div class="input-group">
                    <form-input-date
                        v-model="condition.date_from"
                    ></form-input-date>
                    <span class="input-group-text">〜</span>
                    <form-input-date
                        v-model="condition.date_to"
                    ></form-input-date>
                </div>
            </div>
            <div class="col-10">
                <button @click="search()" :disabled="loading_count > 0" class="btn btn-primary">表示</button>
                <button @click="searchThisMonth()" :disabled="loading_count > 0" class="btn btn-link">今月</button>
                <button @click="searchLastMonth()" :disabled="loading_count > 0" class="btn btn-link">先月</button>
            </div>
        </div>
    </section>

    <section v-if="loading_count > 0" class="section">
        <inline-loader></inline-loader>
    </section>
    <template v-else>
        <template v-if="stats.length">
            <!-- グラフ出力 -->
            <section v-if="chart !== null" class="section">
                <div class="row align-items-end">
                    <div class="col-24 offset-6">
                        <doughnut-graph
                            :chart="chart"
                            :width="800"
                            :height="300"
                        ></doughnut-graph>
                    </div>
                    <div class="col-6 text-end">
                        <div class="h2">合計: <strong class="display-6 text-primary">{{ total }}</strong> 件</div>
                    </div>
                </div>
            </section>

            <!-- 表出力 -->
            <section class="section">
                <div class="col-36 text-end">
                    <button class="btn btn-link" @click="openAllAccordion()" v-if="open_accordion.length < stats.length">全て展開 <i class="bi bi-chevron-down"></i></button>
                    <button class="btn btn-link" @click="closeAllAccordion()" v-else>全て閉じる <i class="bi bi-chevron-up"></i></button>
                </div>
                <div class="mb-3">
                    <table class="table table-sm table-bordered table-layout-fixed">
                        <thead>
                            <tr class="table-dark">
                                <th class="text-center col-3">チャネル</th>
                                <th class="col-1"></th>
                                <th class="text-center col-5">送信元ページ</th>
                                <th class="col-1"></th>
                                <th class="text-center col-18"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <!-- チャンネル -->
                            <template v-for="(channel, index_channel) in stats" :key="channel.channel_name">
                                <template v-for="(detailed_channel, index_detailed_channel) in channel.channel" :key="detailed_channel.detailed_channel_name">
                                    <tr>
                                        <template v-if="index_detailed_channel === 0">
                                            <td class="align-middle" :rowspan="channel.channel.length">{{ channel.channel_name }}</td>
                                            <td class="align-middle text-end" :rowspan="channel.channel.length">{{ channel.channel_total }}</td>
                                        </template>
                                        <td class="align-middle medium">{{ detailed_channel.detailed_channel_name }}</td>
                                        <td class="align-middle text-end">{{ detailed_channel.total }}</td>
                                        <td class="align-middle">
                                            <!-- 明細アコーディオン -->
                                            <div class="accordion response_accordion">
                                                <div class="accordion-item">
                                                    <!-- ヘッダーボタン -->
                                                    <h3 class="accordion-header">
                                                        <button
                                                            class="accordion-button"
                                                            type="button"
                                                            :class="{'collapsed': open_accordion.includes(index_channel + '-' + index_detailed_channel) === false}"
                                                            @click="toggleAccordion(index_channel + '-' + index_detailed_channel)"
                                                        >
                                                            明細を表示
                                                        </button>
                                                    </h3>
                                                    <!-- アコーディオンヘッダー -->
                                                    <div
                                                        class="accordion-collapse collapse"
                                                        :class="{'show': open_accordion.includes(index_channel + '-' + index_detailed_channel)}"
                                                        aria-labelledby="panelsStayOpen-heading"
                                                    >
                                                        <div class="accordion-body">
                                                            <div class="sticky-table">
                                                                <table v-if="detailed_channel" class="table table-sm">
                                                                    <thead>
                                                                        <tr class="table-dark">
                                                                            <th class="text-center" style="min-width: 100px">会社名</th>
                                                                            <th class="text-center" style="min-width: 100px">大手企業</th>
                                                                            <th class="text-center" style="min-width: 130px">営業担当</th>
                                                                            <th class="text-center" style="min-width: 130px">反響日</th>
                                                                            <th class="text-center" style="min-width: 100px">規格</th>
                                                                            <th class="text-center" style="min-width: 100px">その他規格</th>
                                                                            <th class="text-center" style="min-width: 130px">初回営業日</th>
                                                                            <th class="text-center" style="min-width: 130px">反響分類</th>
                                                                            <th class="text-center" style="min-width: 130px">都道府県</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr v-for="project in detailed_channel.projects" :key="project.project_id">
                                                                            <td class="align-middle text-nowrap px-4">
                                                                                <router-link :to="{name: 'ProjectEdit', params: {id: project.project_id}}">{{ project.customer.company_name }}</router-link>
                                                                            </td>
                                                                            <td class="align-middle px-4 text-center">{{ project.customer.is_major_label }}</td>
                                                                            <td class="align-middle px-4">{{ project.sales_user.user_name }}</td>
                                                                            <td class="align-middle px-4">{{ project.contact_date_label }}</td>
                                                                            <td class="align-middle text-nowrap px-4">{{ project.standards_label }}</td>
                                                                            <td class="align-middle text-nowrap px-4">{{ project.standard_note }}</td>
                                                                            <td class="align-middle px-4">{{ project.first_visited_date_label }}</td>
                                                                            <td class="align-middle px-4">{{ getChannelCategory(project) }}</td>
                                                                            <td class="align-middle px-4">{{ project.area.area_name }}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </template>
                            </template>
                        </tbody>
                    </table>
                </div>
            </section>
        </template>
        <template v-else>
            <section class="section">
                <p class="alert alert-secondary">※反響実績レポートがありません</p>
            </section>
        </template>
    </template>
</template>

<script>
import Department from '@/models/entities/department';
import InlineLoader from '@/components/tools/InlineLoader.vue';
import Project from '@/models/entities/project';
import FormInputDate from '@/components/forms/FormInputDate';
import FormCheckboxes from '@/components/forms/FormCheckboxes';
import DoughnutGraph from '@/components/reports/DoughnutGraph';

export default {
    name: 'ReportResponse',
    components: {
        InlineLoader,
        FormInputDate,
        FormCheckboxes,
        DoughnutGraph,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showErrorMessage',
        'showMessage',
    ],
    data() {
        return {
            // ローディング
            loading_count: 0,

            // 検索フォーム
            options_department: [],

            // 検索条件
            condition: {
                departments: [],
                date_from: null,
                date_to: null,
            },

            // 表示データ
            stats: [],
            total: 0,
            chart: null,

            // アコーディオン開閉フラグ "チャネルINDEX-送信元ページINDEX" という文字列の配列
            open_accordion: [],
        }
    },
    mounted() {
        this.fetchDepartment();
        this.setThisMonth();
    },
    methods: {
        // 反響分類の取得
        getChannelCategory(project) {
            // 送信元ページの反響分類を優先で返却
            if (project.detailed_channel) {
                return project.detailed_channel.channel_category_display;
            }
            if (project.channel) {
                return project.channel.channel_category_label;
            }
            return "";
        },
        // 月初、月末の取得
        setThisMonth() {
            let date_obj = new Date();

            // 今月の月初取得
            const year = date_obj.getFullYear().toString();
            const month = (date_obj.getMonth() + 1).toString().padStart(2, '0');
            this.condition.date_from = year + '-' + month + '-01';

            // 今月の月末取得
            const end_of_month = new Date(date_obj.getFullYear(), date_obj.getMonth() + 1, 0).getDate();
            this.condition.date_to = year + '-' + month + '-' + end_of_month;
        },
        // 今月表示
        searchThisMonth() {
            this.setThisMonth();
            this.search();
        },
        searchLastMonth() {
            // 先月をセット
            let date_obj = new Date();

            // 先月の月初取得
            const year = date_obj.getFullYear().toString();
            const month = (date_obj.getMonth()).toString().padStart(2, '0');
            this.condition.date_from = year + '-' + month + '-01';

            // 先月の月末取得
            const end_of_month = new Date(date_obj.getFullYear(), date_obj.getMonth(), 0).getDate();
            this.condition.date_to = year + '-' + month + '-' + end_of_month;

            this.search();
        },
        fetchDepartment() {
            this.loading_count++;

            // 事業部マスタ取得
            this.$http.get('/master/department')
            .then(response => {
                let classic_departments = []; //新規事業でない事業部
                for (let row of response.data) {
                    let department = new Department(row);
                    this.options_department.push({value: department.department_id, label: department.department_name});

                    //新規事業部以外
                    if (0 === department.is_new_business) {
                        classic_departments.push(department.department_id);
                    }
                }

                //事業部未登録の場合
                if (this.options_department.length === 0) {
                    this.showErrorMessage('事業部が登録されていません。先に事業部を登録してください');
                }

                // 本レポートのみ、事業部の初期選択を、「新規事業部フラグ」の立っていない事業部全てとする
                this.condition.departments.splice(0);
                this.condition.departments = classic_departments;

                this.search();
            })
            .finally(() => {
                this.loading_count--;
            })
        },
        // 検索
        search() {
            // 集計期間チェック
            if (this.invalidDate()) {
                return;
            }

            this.fetchResponseReport();
        },
        invalidDate() {
            if (
                this.$helper.isBlank(this.condition.date_from)
                || this.$helper.isBlank(this.condition.date_to)
            ) {
                this.showErrorMessage('集計期間を設定してください');
                return true;
            }
        },
        // 競合レポート 表取得
        fetchResponseReport() {
            this.loading_count++;

            // 表示データ初期化
            this.stats.splice(0);

            this.$http.get('/report/response', {
                params: this.condition,
            })
            .then(response => {
                this.stats = response.data;
                this.total = 0;

                this.stats.forEach((stat) => {
                    stat.channel.forEach((channel) => {
                        channel.projects = channel.projects.map((project) => {
                            return new Project(project);
                        });
                    });

                    this.total += stat.channel_total;
                });
                this.fetchResponseChart();
            })
            .finally(() => {
                this.loading_count--;
            })
        },
        // 競合レポート グラフ取得
        fetchResponseChart() {
            this.loading_count++;
            // 表示データ初期化

            this.$http.get('/report/response/chart', {
                params: this.condition,
            })
            .then((response) => {
                this.chart = response.data.labels.length ? response.data : null;
            })
            .finally(() => {
                this.loading_count--;
            })
        },

        // アコーディオン開閉 open_accordion の配列に存在したら開いている状態
        toggleAccordion(index_pair) {
            if (this.open_accordion.includes(index_pair)) {
                // オープン済みであれば閉じる処理
                const remove_index = this.open_accordion.indexOf(index_pair);
                this.open_accordion.splice(remove_index, 1);
            } else {
                // オープンしていなければ開く処理
                this.open_accordion.push(index_pair);
            }
        },

        // 全てのアコーディオン開く
        openAllAccordion() {
            this.open_accordion.splice(0);

            for (let i = 0; i < this.stats.length; i++) {
                for (let k = 0; k < this.stats[i].channel.length; k++) {
                    this.open_accordion.push(i + '-' + k);
                }
            }
        },
        // 全てのアコーディオン閉じる
        closeAllAccordion() {
            this.open_accordion.splice(0);
        },
    }
}
</script>

<style scoped>
.sticky-table {
    max-width: 100%;
    overflow: scroll;
}
.sticky-table th  {
    white-space: nowrap;
}
</style>

<style>
.response_accordion .accordion-button {
    padding: 0.6rem 1.25rem !important;
}
.medium {
    font-size: .8rem;
}
</style>
